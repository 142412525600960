@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.backButton {
  margin-top: toRem(30);
  margin-left: toRem(30);
}

$padding-x: 19;

.card {
  display: flex;
  flex-direction: column;
  height: 1000vh;
  background-color: $color-white-100;
  padding: toRem(30) toRem($padding-x) 0;
  border-radius: toRem(6);
  margin-top: toRem(30);
  margin-right: toRem(30);
  margin-left: toRem(30);
  padding-bottom: toRem(48);
  margin-bottom: toRem(30);
  position: relative;
}

.header {
  display: flex;
  padding-bottom: toRem(24);
  flex-direction: column;
  justify-content: flex-end;
  margin-right: auto;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: toRem(16);
  margin-top: auto;
}
