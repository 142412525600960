@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: toRem(4);
  width: 100%;
}

.label {
  color: $color-purple-500;
  font-size: toRem(13);
}
