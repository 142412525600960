@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.header__nav {
  padding: 0 toRem(10);

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: toRem(10);
  }

  &-link {
    cursor: pointer;
    color: $color-white-100;
  }

  &-link:hover {
    color: $color-primary-marine;
  }
}
