@import 'src/styles/abstracts/_functions.scss';

.dateRange {
  display: flex;
  justify-content: space-between;
  gap: toRem(10);
}
.filterButton {
  width: max-content;
}
.filterContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: toRem(20);
}

.filterItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: toRem(10);
}