@import 'src/styles/abstracts/_mixins.scss';

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  position: relative;
  @include fitHeight();
}
