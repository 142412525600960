@import 'src/styles/abstracts/_functions.scss';
.transactionWrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: toRem(12) 0; 
}

.label {
    margin-right: toRem(12);
}