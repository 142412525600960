@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.dropdown {
  position: relative;
  width: 100%;
  border: toRem(1) solid $color-grey-200;
  border-radius: toRem(10);
  padding: toRem(5) toRem(12);
  min-height: toRem(22);
  max-height: max-content;

  &--active {
    border-radius: unset;
    border-top-left-radius: toRem(10);
    border-top-right-radius: toRem(10);
    border-bottom-color: unset;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    gap: toRem(9);
    min-height: toRem(24);

    color: $color-purple-500;
  }

  &__button {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    outline: none;
    border: none;
    font-size: toRem(14);
    text-align: start;
    color: $color-purple-500;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }
  }

  &__selected-value {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: toRem(22);
    gap: toRem(10);
  }

  &__arrow {
    position: absolute;
    transition: 0.2s;
    top: 50%;
    transform: translateY(-55%);
    right: 0;

    &--opened {
      transform: rotate(180deg) translateY(55%);
    }
  }

  &__list {
    display: none;
    list-style-type: none;
    position: absolute;
    left: toRem(-1);
    top: 100%;
    width: 100%;
    border: toRem(1) solid $color-grey-200;
    border-radius: toRem(10);
    background-color: $color-white-100;
    font-size: toRem(14);

    &--active {
      display: block;
      z-index: 20;
      border-radius: unset;
      border-top: 0;
      border-bottom-left-radius: toRem(10);
      border-bottom-right-radius: toRem(10);
    }
  }
}
