html {
  height: 100%;

  body {
    margin: 0;
    font-family: 'Moderat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #root {
      margin: auto;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  font-family: 'Moderat', sans-serif;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
}
