@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.menu {
  display: flex;
  align-items: center;
  padding-left: toRem(14);
}

.drawerCard {
  display: flex;
  flex-direction: column;
  padding: toRem(52) toRem(0) toRem(0) toRem(52);
}
.label {
  font-family: 'Moderat', sans-serif;
  font-size: toRem(21);
  line-height: toRem(70);
  font-weight: 400;
  padding-left: toRem(12);

  &:hover {
    color: $color-white-100;
  }
}

.link {
  display: flex;
  align-items: center;
}
