@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.main {
  display: flex;
  position: relative;
  align-items: center;
  gap: toRem(8);
  cursor: pointer;
  margin-left: toRem(10);
}

.rp {
  padding-right: toRem(20);
}

.alignRight {
  right: 0px;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: toRem(8);
  position: absolute;
  top: 250%;
  background-color: $color-primary-purple;
  z-index: 20;
  width: fit-content;
  border-radius: toRem(15);
  padding: toRem(12) toRem(22);
}

.menuRow {
  cursor: pointer;
}

.menuRow:hover {
  color: $color-primary-marine;
}
