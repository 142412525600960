@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_mixins.scss';

$padding-top: 12;
$padding-left: 10;
$padding-right: 22;
$padding-bottom: 12;
$border-width: 1;

.wrapper {
  position: relative;
  @include flexBox(row);
  height: min-content;
  background-color: $color-white-300;
  border: toRem($border-width) solid $color-white-300;
  border-radius: toRem(10);
  padding: toRem($padding-top) toRem($padding-left) toRem($padding-bottom);

  &--focused {
    background-color: $color-white-100;
    border: toRem($border-width) solid $color-primary-marine;
  }

  .textArea {
    border: none;
    outline: none;
    padding: 0 toRem($padding-right) 0 toRem($padding-left);
    width: calc(100% - #{toRem($padding-left + $padding-right + 2 * $border-width)});
    background-color: inherit;
    font-size: toRem(14);
    font-weight: 600;
    resize: none;
    &:focus ~ .clear-icon {
      visibility: visible;
    }
  }

  .clear-icon {
    position: absolute;
    top: toRem(6);
    right: toRem($padding-right);
    visibility: hidden;
    cursor: pointer;
    z-index: 100;
  }
}
