@import 'src/styles/abstracts/functions.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: toRem(20);
}

.container {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: toRem(5);
  }
}
.linkButtonCard {
  display: flex;
  flex-direction: row-reverse;
}

.enabledForAll {
  input:disabled + span {
    cursor: not-allowed;
  }
}
