@import 'src/styles/abstracts/variables/_colors.scss';

.errorContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $color-white-100;
}
