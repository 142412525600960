@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background-color: $color-white-100;
  padding: toRem(27) 0px toRem(10) toRem(24);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.customer-name {
  margin: 0;
  line-height: toRem(28);
}

.content {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  padding: toRem(20) toRem(24) toRem(15) toRem(24);
}
