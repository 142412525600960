@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.navigation-tabs {
  height: toRem(41);

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: toRem(32);
}
