@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: $color-white-100;
  border-radius: toRem(6);
  padding: toRem(20) toRem(23) toRem(20) toRem(23);
}

.img-box {
  min-width: 40%;

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}
