@import 'src/styles/abstracts/variables/_colors.scss';

.header__logo {
  &-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-text {
    color: $color-white-100;
    font-weight: 500;
  }
}
