@import 'src/styles/abstracts/functions.scss';

.card {
    display: flex;
    flex-direction: column;
}

.cardContent {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: toRem(20);
}

.label {
    width: toRem(300);
}

.gridCard {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: toRem(20);
    margin-bottom: toRem(24);
}

.inputBox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: toRem(24); 
}

.input {
    display: flex;
    flex-direction: row;
    gap: toRem(16);
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: toRem(16);
    margin-top: auto;
}

@media screen and (max-width: toRem(1090)) {
    .input {
        flex-direction: column;
        justify-content: center;
    }
    .cardContent {
        justify-content: flex-start;
    }
    .inputCard {
        flex-grow: 1;
    }
}