@import 'src/styles/abstracts/functions.scss';

.icon {
  align-content: end;
  padding-left: toRem(5);
  padding-right: toRem(5);
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.input {
  width: 100%;
}