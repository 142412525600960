@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.table-cell {
  @include flexBox();
}

.right-border {
  border-right-color: $color-black-100;
  border-right-style: solid;
  border-right-width: 0.063rem;
}

.empty-cell {
  width: 100%;
  height: 100%;
}

.permission-cell {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  word-break: keep-all;
}

.even-row {
  background-color: $color-white-200 !important;
}

.info-icon {
  @include flexBox();
  cursor: pointer;
}

.info-icon-tooltip {
  background-color: $color-light-marine !important;
  padding: toRem(14) !important;
  border-radius: toRem(15) !important;
}
