@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.main {
  width: toRem(500);
}

.buttons {
  width: 100%;
  @include flexBox(row);
  gap: toRem(23);
  margin-top: toRem(30);
}

.title {
  font-size: toRem(18);
  font-weight: 400;
  width: 100%;
}

.comment {
  margin-top: toRem(13);
}
.dropdown {
  margin: toRem(24)  toRem(0);
}