@import 'src/styles/abstracts/_functions.scss';

.title {
  display: flex;
  align-items: center;
  gap: toRem(12);

  label {
    input:disabled + div {
      cursor: not-allowed;
    }
  }
}
