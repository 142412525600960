@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.status-stamp {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: toRem(5);
  width: toRem(104);
  height: toRem(27);
  text-align: center;
  background-color: $color-white-300;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  &.Pending {
    color: $color-orange-100;
  }

  &.Failed {
    color: $color-error-700;
  }

  &.Declined {
    color: $color-error-700;
  }

  &.Reversed {
    color: $color-orange-100;
  }

  &.Expired {
    color: $color-grey-400;
  }

  &.Approved {
    color: $color-success-700;
    background-color: $color-third-marine;
  }

  &.Initiated {
    color: $color-yellow-100;
  }

  &.InProgress {
    color: $color-success-700;
  }
  
  &.Reversed {
    color: $color-orange-100;
  }

  &.Unknown {
    color: $color-grey-400;
  }
}