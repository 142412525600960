@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.dropdown__option {
  padding: toRem(5) toRem(21);

  &:hover {
    color: $color-green-700;
    cursor: pointer;
  }

  &--active {
    color: $color-green-700;
  }

  &--hidden {
    display: none;
  }

  &--purple.dropdown__option--active {
    color: $color-primary-marine;
  }

  &--purple:hover {
    color: $color-primary-marine;
  }

  &--inline {
    padding-left: toRem(12);
    padding-right: toRem(24);
  }
}
