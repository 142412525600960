@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.status-stamp {
  font-weight: bold;

  &.successLight {
    color: $color-success-300;
  }

  &.successMedium {
    color: $color-success-500;
  }

  &.successDark {
    color: $color-success-700;
  }

  &.warningLight {
    color: $color-yellow-100;
  }

  &.warningMedium {
    color: $color-orange-100;
  }

  &.warningDark {
    color: $color-warning-700;
  }

  &.errorLight {
    color: $color-error-300;
  }

  &.errorMedium {
    color: $color-error-500;
  }

  &.errorDark {
    color: $color-error-700;
  }
}

.border {
  border-radius: toRem(28.5);
  padding: toRem(4) toRem(12) toRem(2) toRem(12);
  margin: 0 toRem(10) 0 toRem(10);
  border-style: solid;
  border-width: toRem(1);
}
