@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

$padding-x: 21;

$smallWidth: 255;
$mediumWidth: 355;
$largeWidth: 483;

.wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba($color-black-900, 0.5);
  z-index: 20;
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: toRem(10);
  padding: toRem(35) toRem($padding-x);
  top: 0;
  bottom: 0;

  &--white {
    background-color: $color-white-100;
  }

  &--marine {
    background-color: $color-primary-marine;
  }

  &--small {
    right: - toRem($smallWidth);
    width: calc(toRem($smallWidth) - calc(2 * toRem($padding-x)));
    animation: slideInSmall 0.2s linear forwards;
  }
  
  &--medium {
    right: - toRem($mediumWidth);
    width: calc(toRem($mediumWidth) - calc(2 * toRem($padding-x)));
    animation: slideInMedium 0.2s linear forwards;
  }

  &--large {
    right: - toRem($largeWidth);
    width: calc(toRem($largeWidth) - calc(2 * toRem($padding-x)));
    animation: slideInLarge 0.2s linear forwards;
  }
}

@keyframes slideInSmall {
  0% {
    right: -toRem($smallWidth);
  }
  100% {
    right: 0;
  }
}

@keyframes slideInMedium {
  0% {
    right: -toRem($mediumWidth);
  }
  100% {
    right: 0;
  }
}

@keyframes slideInLarge {
  0% {
    right: -toRem($largeWidth);
  }
  100% {
    right: 0;
  }
}


.childrenContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: toRem(15);
}

.closeIcon {
  position: absolute;
  top: toRem(18);
  right: toRem(20);
  cursor: pointer;
}
