@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.filterChip {
  width: max-content;
  height: toRem(17);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white-300;
  color: $color-purple-500;
  font-weight: 500;
  padding: toRem(6) toRem(12) toRem(6) toRem(12);
  cursor: pointer;
}

.filterChipCloseIcon {
  padding-left: toRem(10);
}
