@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: toRem(16);
  margin-top: auto;
}

.topRight {
  position: absolute;
  top: toRem(5);
  right: toRem(5);
  background-color: $color-secondary-marine;
  padding: 10px;
  box-shadow: 0px 3px 16px 0px rgba(129, 78, 250, 0.15);
  width: toRem(413);
  height: toRem(45);
  display: flex;
  align-content: center;
  justify-content: center;
  span {
    height: max-content;
    padding-right: toRem(10);
    padding-top: toRem(4);
    text-transform: uppercase;
    color: $color-primary-purple;
    font-weight: bold;
  }
  flex-wrap: wrap;
}

.readOnly {
  background-color: $color-grey-300;
  span {
    color : $color-grey-500;
  }

  cursor: not-allowed;
  * {
    cursor: not-allowed !important;
  }
}