@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_mixins.scss';

$padding-left: 12;
$padding-right: 22;
$border-width: 1;

.input {
  width: calc(100% - #{toRem($padding-left + $padding-right + 2 * $border-width)});
  height: toRem(30);
  font-weight: 600;
  font-style: normal;
  border-radius: toRem(10);
  outline: none;
  padding-left: toRem($padding-left);
  padding-right: toRem($padding-right);
  overflow: hidden;
  border: toRem($border-width) solid $color-white-300;
  background-color: $color-white-300;
  position: relative;
  font-size: toRem(14);

  &:focus {
    background-color: $color-white-100;
    border: toRem($border-width) solid $color-primary-marine;
  }
  &-focus {
    background-color: $color-white-100;
    border: toRem($border-width) solid $color-primary-marine;
  }

  &:disabled {
    cursor: not-allowed;
    color: $color-grey-500;
  }
}

.has-error {
  border-color: $color-error-700 !important;
}

.wrapper {
  position: relative;
  @include flexBox(row);

  .input:focus + .close-icon,
  .input:focus ~ .close-icon {
    visibility: visible;
  }
}

.close-icon {
  position: absolute;
  right: toRem(calc($padding-right - 12));
  visibility: hidden;
  cursor: pointer;
  z-index: 100;
}

.readonly {
  width: 100%;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  height: toRem(30);
  word-wrap: break-word;
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: toRem(2);
  color: $color-error-700;
  font-size: toRem(10);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(13);
}
