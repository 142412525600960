@import 'src/styles/abstracts/_functions.scss';

.container {
  width: toRem(500);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: toRem(20);
}

.child {
  width: 100%;
  & > * {
    padding-bottom: toRem(10);
  }
}

.button {
  padding-top: toRem(20);
  padding-bottom: toRem(20);
  display: flex;
  justify-content: center;
}