@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.content {
  width: 100%;
  height: toRem(40);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
