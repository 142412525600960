@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.dropdown {
  width: toRem(147);
  height: 100%;

  &__button {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    outline: none;
    border: none;
    text-align: start;

    &:hover {
      cursor: pointer;
    }
  }

  &__selected-value {
    width: 100%;

    &--purple {
      color: $color-white-100;
    }
  }

  &__dropdown {
    width: fit-content;
    list-style-type: none;
    display: none;
    background-color: $color-white-100;
    position: absolute;
    font-size: toRem(14);
    left: 0;
    top: 0;
    transform: translate(0, 40%);
    border-radius: toRem(20);
    padding: toRem(15) 0 toRem(11);

    &--active {
      display: block;
      z-index: 20;
    }

    &--purple {
      background-color: $color-primary-purple;
      color: $color-white-100;
    }

    &--inline {
      padding: toRem(8) 0 toRem(11);
      transform: translate(0, 36%);
    }
  }
}
