@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  background-color: $color-white-100;
  box-shadow: 0 toRem(3) toRem(3) 0 $color-boxshadow-black;
  padding: toRem(0) toRem(24);
}

.table-wrapper {
  @include fitHeight();
}