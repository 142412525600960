@import 'src/styles/abstracts/_functions.scss';

.flex-row {
  display: flex;
  flex-direction: row;
}

.slash {
  padding: 0 0.5rem;
}
