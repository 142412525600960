@import 'src/styles/abstracts/_functions.scss';

.card {
    display: flex;
    flex-direction: column;
    gap: toRem(16);
}

.inputsCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dropdownCard {
    display: flex;
    flex-direction: column;
    gap: toRem(12);
    flex-grow: 1;
    max-width: toRem(230);
}

.amountInput {
    align-content: flex-end;
    flex-grow: 1;
    max-width: toRem(230);
}
