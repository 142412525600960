@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.main {
  display: flex;
  flex-direction: column;
  gap: toRem(20);
  width: 100%;
}

.white-box {
  $padding-left: 23;
  $padding-right: 23;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: calc(100% - #{toRem($padding-left + $padding-right)});
  overflow: hidden;
  background-color: $color-white-100;
  border-radius: toRem(6);
  padding: toRem(20) toRem($padding-right) toRem(20) toRem($padding-left);
}

.reviewed-label {
  padding-top: 2rem;
  text-align: center;
}

.documents {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.img-container {
  $gap: 10;
  display: flex;
  flex-direction: column;
  gap: toRem($gap);
  padding-bottom: 1rem;
  flex: 1 1 auto;

  img {
    max-height: 100%;
    width: auto;
    object-fit: contain;
  }
  iframe {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
}

.info-wrapper {
  margin-right: toRem(5);
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: toRem(14);
}