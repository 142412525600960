@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.filters {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: toRem(20);

  margin-bottom: toRem(12);

  &__country {
    width: toRem(250);
  }
}

.table-wrapper {
  @include fitHeight();
}
