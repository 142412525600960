@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

$padding-x: 21;
$width: 355;

.wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba($color-black-900, 0.5);
  z-index: 20;
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: toRem(10);
  background-color: $color-white-100;
  padding: toRem(35) toRem($padding-x);
  right: - toRem($width);
  top: 0;
  bottom: 0;
  width: calc(toRem($width) - calc(2 * toRem($padding-x)));
  animation: slideIn 0.2s linear forwards;
}

@keyframes slideIn {
  0% {
    right: - toRem($width);
  }
  100% {
    right: 0;
  }
}

.childrenContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: toRem(15);
}

.closeIcon {
  position: absolute;
  top: toRem(18);
  right: toRem(20);
  cursor: pointer;
}
