@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

$padding-x: 19;

.customer-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .customer-info {
    z-index: 10;
    background-color: $color-white-100;
    box-shadow: 0 toRem(3) toRem(3) 0 $color-boxshadow-black;
    padding: toRem(3) toRem(24);

    .breadcrumbs-wrapper {
      padding-top: toRem(7);
    }

    .customer-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: toRem(41);
    }

    .customer-name {
        margin: 0;
        line-height: toRem(28);
      }                              
    }
  }

  .customer-details {
    flex: 1 1 auto;
    height: toRem(83);
    width: 100%;
    display: flex;

    .customer-widgets {
      flex: 1 1;
      overflow: auto;
      scrollbar-gutter: stable;
    }
  }
