@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.button {
  padding: 0 toRem(20);
  height: toRem(30);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: toRem(5);
  line-height: toRem(16);
}
