@import 'src/styles/abstracts/variables/_colors.scss';

.home-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: center;

  height: 100%;

  overflow: hidden;

  &__background {
    position: absolute;

    width: 100%;
    height: 100%;
  }

  &__background-mask {
    width: 100%;
    height: 100%;
  }

  &__search {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
  }
}
