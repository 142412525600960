@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/functions.scss';

.table-wrapper {
  @include fitHeight();
}

.title {
  @include flexBox(row, space-between);
  margin-bottom: toRem(19);
}
