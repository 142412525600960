@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.customer-field {
  display: flex;
  flex-wrap: wrap;

  & > * {
    font-size: toRem(14);
    margin-top: toRem(2);
    margin-bottom: toRem(2);
  }

  &__label {
    color: $color-grey-200;
    font-weight: 400;
  }

  &__value {
    color: $color-white-100;
    font-weight: 500;
  }
}
