@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

$padding-x: 19;

.content {
  padding: toRem(20);
  @include fitHeight();
}

.content-box {
  background-color: $color-white-100;
  padding: toRem(30);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: toRem(40);
}

.cancel-button {
  margin-right: toRem(16);
}

.input-wrapper {
  padding-right: toRem(200);
}

.input-wrapper-right-column {
  padding-left: toRem(20);
}

.input {
  padding-bottom: toRem(16);
  width: toRem(300);
}

.left-column {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}