@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

$padding-left: 22;
$padding-right: 22;

.main {
  color: $color-primary-purple;
  display: flex;
  flex-direction: column;
  gap: toRem(11);
  width: calc(100% - #{toRem($padding-left + $padding-right)});
  background-color: $color-white-100;
  border-radius: toRem(6);
  padding: toRem(20) toRem(22) toRem(14) toRem(22);
  box-shadow: 0 toRem(8) toRem(16) 0 $color-boxshadow-purple;
}

.headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: toRem(16);
  font-weight: 600;
  line-height: toRem(24);
  padding-left: toRem(4);
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions {
  display: flex;
  align-items: center;
  gap: toRem(16)
}
.time-text {
  font-size: toRem(13);
}
