@mixin flexBox($direction: column, $justifyContent: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: center;
  flex-direction: $direction;
}

@mixin fitHeight() {
  flex: 1 1 auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

@mixin flexBoxTab() {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: toRem(20);
  padding: toRem(20);
}