@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.button {
  padding: 0 toRem(30);
  height: toRem(37);
  border-radius: 500px;
  font-size: 1rem;
  cursor: pointer;
  border: toRem(3) solid transparent;
  font-weight: 700;
  position: relative;

  &.primary {
    color: $color-primary-purple;
    background-color: $color-primary-marine;

    &:disabled {
      background-color: $color-grey-300;
    }

    &:hover:not([disabled]) {
      color: $color-primary-marine;
      background-color: $color-green-700;
    }

    &:active:not([disabled]) {
      color: $color-primary-marine;
      background-color: $color-primary-purple;
    }
  }

  &.secondary {
    background-color: transparent;
    border: toRem(3) solid $color-primary-marine;

    &:disabled {
      border: toRem(3) solid $color-grey-300;
    }

    &:hover:not([disabled]) {
      border: toRem(3) solid $color-green-700;
    }

    &:active:not([disabled]) {
      background-color: $color-primary-marine;
      border: toRem(3) solid $color-primary-marine;
    }
  }

  &.third {
    color: $color-primary-marine;
    background-color: $color-green-700;
    &:disabled {
      background-color: $color-grey-300;
    }

    &:hover:not([disabled]) {
      cursor: pointer;
      color: $color-primary-marine;
      background-color: $color-primary-purple;
    }

    &:active:not([disabled]) {
      color: $color-primary-marine;
      background-color: $color-primary-purple;
    }
  }

  &:disabled {
    color: $color-grey-500;
    cursor: not-allowed;
    box-shadow: none;
  }
}
