@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

$padding-left: 18;
$padding-right: 18;

.customer-pii {
  position: relative;
  color: $color-white-100;
  width: 15%;
  min-width: calc(#{toRem(220)} - #{toRem($padding-left + $padding-right)});
  background-color: $color-primary-purple;
  padding: toRem(14) toRem(18);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;

  &__header {
    display: flex;
    justify-content: space-between;
  }
  
  &__line {
    height: toRem(1);
    background-color: $color-primary-marine;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: toRem(20);
    margin-top: toRem(13);
  }
}