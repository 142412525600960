@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.button {
  &:hover:not([disabled]) {
    path {
      fill: $color-primary-marine;
    }
  }
}
