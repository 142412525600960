@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

$padding-x: 19;

.content {
  padding: toRem(30) toRem(29) toRem(75);
  overflow-y: auto;

}

.content-box {
  width: calc(100% - toRem(calc($padding-x * 2)));
  height: min(100%, max-content);
  background-color: $color-white-100;
  padding: toRem(30) toRem($padding-x) 0;
  border-radius: toRem(6);
  @include fitHeight();
}