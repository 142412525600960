@import 'src/styles/abstracts/_functions.scss';

.contentWrapper {
    display: flex;
    flex-direction: column;
    gap: toRem(12);
}

.icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.noAction {
    display: flex; 
    flex-direction: column-reverse; 
    align-items: center; 
    margin-top: toRem(16);
}