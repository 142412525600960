@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.transaction-history-tab {
  overflow: hidden;
  height: 100%;
  display: flex;

  &__content {
    @include fitHeight();
    overflow-x: hidden;
    padding: toRem(30) toRem(29) toRem(60);
  }
}
