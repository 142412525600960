@font-face {
    font-family: "Moderat";
    font-weight: 100;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Thin-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Thin-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 100;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Thin.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Thin.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 200;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Extralight-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Extralight-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 200;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Extralight.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Extralight.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 300;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Light-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Light-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 400;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Regular-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Regular-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 400;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Regular.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Regular.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 400;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Light.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Light.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 500;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Medium-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Medium-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 500;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Medium.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Medium.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 600;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Semibold-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Semibold-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 600;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Semibold.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Semibold.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 700;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Bold-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Bold-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 700;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Bold.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Bold.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 800;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Extrabold-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Extrabold-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 800;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Extrabold.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Extrabold.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 900;
    font-style: italic;
    src: 
        url("/assets/fonts/Moderat-Black-Italic.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Black-Italic.woff") format('woff'); 
}

@font-face {
    font-family: "Moderat";
    font-weight: 900;
    font-style: normal;
    src: 
        url("/assets/fonts/Moderat-Black.woff2") format('woff2'),
        url("/assets/fonts/Moderat-Black.woff") format('woff'); 
}
