@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.buttonCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transactions-widget {
  @include fitHeight();
  background-color: $color-white-100;
  border-radius: toRem(6);
  gap: toRem(11);
  padding: toRem(20) toRem(22) toRem(14) toRem(22);
  box-shadow: 0 toRem(8) toRem(16) 0 $color-boxshadow-purple;

  &__table-wrapper {
    @include fitHeight();
    overflow: hidden;
  }
}


