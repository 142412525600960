@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba($color-black-900, 0.5);
  @include flexBox();
  z-index: 20;
}

.content {
  position: relative;
  background-color: $color-white-100;
  padding: toRem(35) toRem(40);
  border-radius: toRem(10);
}

.closeIcon {
  position: absolute;
  top: toRem(18);
  right: toRem(20);
  cursor: pointer;
}
