@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.vertical-line {
  margin: 0 toRem(10);

  border-left: toRem(1) solid;

  &--small {
    height: 60%;
  }

  &--medium {
    height: 90%;
  }
  
  &--large {
    height: 100%;
  }
  
  &--white {
    border-color: $color-white-100;
  }

  &--light-white {
    border-color: $color-white-300
  }

  &--extra-light-grey {
    border-color: $color-grey-200;
  }

  &--light-grey {
    border-color: $color-grey-300;
  }

  &--dark-grey {
    border-color: $color-grey-700;
  }
  &--marine {
    border-color: $color-primary-marine;
  }
}