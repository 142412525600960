@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/functions.scss';
@import 'src/styles/abstracts/variables/colors.scss';

.title {
  @include flexBox(row, space-between);
  margin-bottom: toRem(19);
}

.titleTopup {
  @include flexBox(row, flex-end);
  margin-bottom: toRem(19);
}

.emptyCard {
  padding: toRem(16);
}

.buttons {
  @include flexBox(row, space-between);
  gap: toRem(12);
}

.actions {
  @include flexBox(row, flex-start);
  gap: toRem(16);
}

.backButton {
  & > button {
    color: $color-green-700;
  }
}
