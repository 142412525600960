@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.link {
  display: flex;
  color: $color-green-700;
  font-size: toRem(14);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(24);
  text-decoration-line: underline;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: $color-grey-400;
}
