@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.card {
    margin: toRem(20);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: toRem(1000);
}

.navigationCard {
    margin: toRem(12);
    box-sizing: border-box;
}

.navigationItems {
    display: flex;
    flex-direction: column;
    border: toRem(2) solid $color-white-100;
    border-radius: 20%;
    align-items: flex-start;
    background-color: $color-white-300;
    box-shadow: toRem(0) toRem(8) toRem(16) rgba(129, 78, 250, 0.2);
    cursor: pointer;
}

.iconFrame {
    display: flex;
    margin: toRem(24) toRem(24) toRem(12) toRem(16);
    padding: toRem(12);
    border-radius: 50%;
    background-color: $color-white-100;
    align-items: center;
}

.label {
    padding: toRem(16) toRem(16) toRem(16) toRem(24);
    width: toRem(180);
    height: toRem(40);
}