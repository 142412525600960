.arrow {
  position: absolute;
  transition: 0.2s;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  &--opened {
    transform: rotate(180deg) translateY(50%);
  }
}
