.transactions-widget-single {
  &__table-wrapper {
    flex-grow: 1;
  }

  &__top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
