@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.float-container {
  display: flex;
  color: $color-purple-500;
  background-color: white;
  line-height: toRem(24);
}

.float-child {
  flex: 1;
}

.secondColumn {
  border-left: toRem(1) solid $color-grey-300;
  padding-left: toRem(10);
}

.subTitle {
  font-weight: 500;
  font-size: toRem(16);
}

.text {
  font-weight: 400;
  font-size: toRem(16);
}

.moneyAmount {
  font-weight: 600;
  font-size: toRem(18);
}
