@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.riskRatingLabel { 
  font-weight: bold; 
  color:$color-purple-500;
  position: absolute;
  bottom: 0;
  left: 0;
}
