@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.iconCell {
    @include flexBox(row, flex-start);
    gap: toRem(12);
    width: toRem(200)
}

.currencyCode {
    width: toRem(45);
    margin: toRem(6);
}