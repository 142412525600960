@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/_functions.scss';

.pair {
  @include flexBox(row, left);
  gap: toRem(5);

  span {
    font-size: toRem(14);
  }

  img {
    width: toRem(18);
    height: toRem(18);
  }
}
