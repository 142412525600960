body {
  background-color: $color-white-300;
  color: $color-primary-purple;
  height: 100vh;
  
  #root {
    height: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

* {
  @-moz-document url-prefix() {
    & {
      scrollbar-width: thin;
      scrollbar-color: $color-primary-marine $color-purple-500;
    }
  }

  &::-webkit-scrollbar {
    $scrollbar-size: 4;
    width: toRem($scrollbar-size);
    height: toRem($scrollbar-size);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary-marine;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-purple-500;
  }
}

:root {
  --color-white-100: #{$color-white-100};
  --color-white-200: #{$color-white-200};
  --color-white-300: #{$color-white-300};
  --color-white-400: #{$color-white-400};
  --color-black-100: #{$color-black-100};
  --color-black-900: #{$color-black-900};
  --color-grey-100: #{$color-grey-100};
  --color-grey-200: #{$color-grey-200};
  --color-grey-300: #{$color-grey-300};
  --color-grey-400: #{$color-grey-400};
  --color-grey-500: #{$color-grey-500};
  --color-grey-700: #{$color-grey-700};
  --color-purple-500: #{$color-purple-500};
  --color-green-700: #{$color-green-700};
  --color-primary-purple: #{$color-primary-purple};
  --color-primary-marine: #{$color-primary-marine};
  --color-success-300: #{$color-success-300};
  --color-success-500: #{$color-success-500};
  --color-success-700: #{$color-success-700};
  --color-warning-300: #{$color-warning-300};
  --color-warning-500: #{$color-warning-500};
  --color-warning-700: #{$color-warning-700};
  --color-error-300: #{$color-error-300};
  --color-error-500: #{$color-error-500};
  --color-error-700: #{$color-error-700};
  --color-light-marine: #{$color-light-marine};
}
