@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.accordionItem {
  position: relative;
  border-radius: toRem(20);
  margin-bottom: toRem(24);
  overflow: hidden;
  box-shadow: toRem(0) toRem(3) toRem(16) toRem(0) $color-purple-700;
}

.accordionHeader {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white-100;
  padding: toRem(30);
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease;
}

.accordionContent {
  background-color: $color-white-100;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  border: toRem(1) solid $color-turquoise-700;
  border-bottom-left-radius: toRem(20);
  border-bottom-right-radius: toRem(20);
  box-shadow: inset toRem(0) toRem(24) toRem(30) -20px $color-purple-700;
  max-height: 0;
  opacity: 0;
  padding: 0;

  &--active {
    padding: toRem(30);
    opacity: 1;
  }
}

.spinnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: rgba($color-black-900, 0.3);
  z-index: 10;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;   
  transition: 0.2s;
  &--opened {
    transform: rotate(180deg);
  }
}
