@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: toRem(32);
    margin-bottom: toRem(24);
  }
  
  
  .highlight {
    background-color: $color-light-marine;
    padding: toRem(6);
  }
  
  .box {
    padding: toRem(6);
  }
