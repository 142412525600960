@import 'src/styles/abstracts/variables/colors';
@import 'src/styles/abstracts/_functions.scss';

.logo {
  width: toRem(89);
  height: toRem(30);

  &--purple {
    path {
      fill: $color-primary-purple;
    }
  }

  &--marine {
    path {
      fill: $color-primary-marine;
    }
  }
}
