@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.main {
  @include flexBox();
  height: 100%;

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: toRem(62);
  }
  
  &__icon-container {
    display: flex;
    position: relative;
    width: toRem(183);
    height: toRem(256);
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    margin-top: toRem(58);
  }

  &__icon-dart {
    position: absolute;
    top: toRem(153);
    left: toRem(102);
  }

  &__icon-dart-shadow {
    position: absolute;
    top: toRem(248);
    left: toRem(94);
  }

  &__title-text {
    color: $color-primary-marine;
    font-size: toRem(92);
    font-weight: 700;
    line-height: toRem(70);
  }

  &__secondary-text {
    color: $color-black-900;
    font-size: toRem(32);
    font-weight: 700;
    line-height: toRem(70);
  }
}
