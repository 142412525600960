@import 'src/styles/abstracts/_functions.scss';

.labels {
  display: flex;
  flex-direction: column;
  padding-top: toRem(32);
  padding-bottom: toRem(20);
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: toRem(16)
}
