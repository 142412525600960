@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.business-accounts-page {
  @include fitHeight();

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  
    background-color: $color-white-100;
    box-shadow: 0 toRem(3) toRem(3) 0 $color-boxshadow-black;
    padding: toRem(3) toRem(24);
  }
}
