@import 'src/styles/abstracts/functions.scss';

.card {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: toRem(16);
  margin-top: auto;
}

.gridCard {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: toRem(20);
  margin-bottom: toRem(24);
}
