@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.widget {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: toRem(11);
  background-color: $color-white-100;
  border-radius: toRem(6);
  padding: toRem(20) toRem(22) toRem(14) toRem(22);
  box-shadow: 0 toRem(8) toRem(16) 0 $color-boxshadow-purple;

  &__title-bar {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
