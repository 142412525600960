@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.search {
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  z-index: inherit;
  height: inherit;

  &__input-box {
    position: relative;
    display: flex;
    align-items: center;
    width: toRem(717);
    height: toRem(45);
    border-radius: toRem(50);
    padding: 0 toRem(21);
    border: toRem(1) solid $color-primary-marine;
    background-color: $color-white-100;

    &--purple {
      border-color: $color-white-100;
      background-color: $color-primary-purple;
    }

    &--error {
      border: toRem(1) solid $color-error-700;
    }

    &--inline {
      width: toRem(400);
      height: toRem(28);
      padding: 0 toRem(12);
    }
  }

  &__input-value {
    flex-grow: 1;
    border: none;
    outline: none;
    color: $color-grey-500;
    font-size: toRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(20);
    background-color: inherit;

    &--purple {
      color: $color-white-100;
    }
  }

  &__error-message {
    align-self: flex-start;
    margin-left: toRem(25);
    margin-top: toRem(8);
    height: toRem(16);
    color: $color-error-700;
    font-size: toRem(13);
    font-style: normal;
    font-weight: 400;
    line-height: toRem(15.6);
  }

  &__icon {
    cursor: pointer;
    display: inline;
  }

  &__button {
    width: toRem(234);
    margin-top: toRem(23);
    text-transform: uppercase;
  }
}
