@import 'src/styles/abstracts/variables/_colors.scss';

.trusted-device-widget {
  text-transform: uppercase;

  &__delete-button {
    justify-content: flex-end;
    margin-left: auto;

    button {
      color: $color-error-900;
    }
  }

  &__content-container {
    position: relative;
  }

  &__error-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
