@import 'src/styles/abstracts/_mixins.scss';
@import 'src/styles/abstracts/_functions.scss';

.limits-tab {
  @include flexBoxTab();
}

.no-limits-available-label {
  @include flexBox(row);
  height: 100%;
}