@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/variables/_colors.scss';

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: toRem(30) toRem(29);
  overflow: hidden;
  border-radius: toRem(6);
  box-shadow: 0 toRem(8) toRem(16) 0 $color-boxshadow-purple;
}

.widget {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: $color-white-100;
  border-radius: toRem(6);
  padding: toRem(20) toRem(22) toRem(14) toRem(22);
  box-shadow: 0 toRem(8) toRem(16) 0 $color-boxshadow-purple;
}
