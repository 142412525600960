@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.main {
  display: flex;
  flex-direction: column;
  gap: toRem(20);
  width: 100%;
}

.white-box {
  $padding-left: 23;
  $padding-right: 23;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: calc(100% - #{toRem($padding-left + $padding-right)});
  overflow: hidden;
  background-color: $color-white-100;
  border-radius: toRem(6);
  padding: toRem(20) toRem($padding-right) toRem(20) toRem($padding-left);
}

.reviewed-label {
  padding-top: 2rem;
  text-align: center;
}

.documents {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.front-back-wrapper {
  $gap: 10;
  display: flex;
  flex-direction: column;
  gap: toRem($gap);

  img {
    max-height: calc(50% - #{toRem($gap)});
    width: auto;
    object-fit: cover;
  }
}

.selfie-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;

  img {
    max-height: 50%;
    width: auto;
    object-fit: cover;
  }
}

.kyc-wrapper-label {
  padding-top: toRem(5);
  padding-bottom: toRem(5);
}

.empty-space {
  height: toRem(5);
}

.info-wrapper {
  margin-right: toRem(5);
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: toRem(14);
}
