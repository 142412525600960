@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.header {
  display: flex;
  justify-content: space-between;

  padding: 0 toRem(30);

  background-color: $color-primary-purple;
  color: $color-white-100;

  height: toRem(54);
  flex-shrink: 0;
}
