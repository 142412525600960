$color-white-100: #FFFFFF;
$color-white-200: #FAFAFA;
$color-white-300: #F5F4F8;
$color-white-400: #F0F0F0;

$color-black-100: #0000000f;
$color-black-900: #000000;

$color-grey-100: #F1F1F1;
$color-grey-200: #D7D3E0;
$color-grey-300: #C7C4D0;
$color-grey-400: #9A9A9A;
$color-grey-500: #707070;
$color-grey-700: #ffffff4d;

$color-purple-500: #716A8A;
$color-purple-700: #814EFA26;
$color-turquoise-700: #00EAD01A;

$color-green-700: #008273;

$color-primary-purple: #110039;
$color-primary-marine: #00EAD0;
$color-light-marine: #EDFFFD;
$color-secondary-marine: rgba(0, 234, 208, 0.1);
$color-third-marine: #E5FDFA;


$color-success-300: #BEFFF7;
$color-success-500: #3ACFBE;
$color-success-700: #009987;

$color-warning-300: #F8F3D8;
$color-warning-500: #E2CA64;
$color-warning-700: #C9A81C;

$color-error-300: #F9DEDD;
$color-error-500: #FF8B87;
$color-error-700: #E75B56;
$color-error-900: #FF2947;

$color-boxshadow-purple: rgba(129, 78, 250, 0.20);
$color-boxshadow-black: rgba(0, 0, 0, 0.08);

$color-orange-100: #F79E1B;
$color-yellow-100: #FFD051;

