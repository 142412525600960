@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.link {
  display: flex;
  align-items: center;
  gap: toRem(6);
  color: $color-primary-purple;
  font-size: toRem(14);
  font-style: normal;
  font-weight: 600;
  line-height: toRem(24);
  cursor: pointer;
}
