@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';

.navigation-tabs {
  &__item {
    height: 100%;

    &--active {
      color: $color-green-700;
      border-bottom: toRem(3) solid $color-green-700;
    }

    &--disabled {
      color: $color-grey-200;
      pointer-events: none; 
    }

    &:hover {
      cursor: pointer;
      color: $color-green-700;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    color: inherit;
  }
}
