@import 'src/styles/abstracts/variables/_colors.scss';
@import 'src/styles/abstracts/_functions.scss';
@import 'src/styles/abstracts/_mixins.scss';

.access-management-page {
  @include fitHeight();

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  
    background-color: $color-white-100;
    box-shadow: 0 toRem(3) toRem(3) 0 $color-boxshadow-black;
    padding: toRem(3) toRem(24);
  }
}

$padding-x: 19;

.content {
  padding: toRem(30) toRem(29) toRem(60);
  @include fitHeight();
}

.content-box {
  width: calc(100% - toRem(calc($padding-x * 2)));
  height: 100%;
  background-color: $color-white-100;
  padding: toRem(30) toRem($padding-x);
  border-radius: toRem(6);
  @include fitHeight();
}